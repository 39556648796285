/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className=" bg-gray-100 min-h-screen text-gray-900 text-lg antialiased ">
        <div className=" max-w-6xl mx-auto px-4 pt-4 pb-8 md:p-8">
          <div className="">
            <Header siteTitle={data.site.siteMetadata.title} />
          </div>
          <div className="relative">
            <main>{children}</main>
          </div>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
