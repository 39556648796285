import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useLayoutEffect } from "react"
import { Spiral as Hamburger } from "hamburger-react"
import DesktopMenu from "./common/DesktopMenu"

import { useStaticQuery, graphql } from "gatsby"
import Logo from "./common/Logo"
function Header({ siteTitle }) {
  const data = useStaticQuery(graphql`
    {
      mainMenu: wpMenu(id: { eq: "dGVybTozNQ==" }) {
        id
        name
        menuItems {
          nodes {
            path
            parentId
            label
            id
            url
          }
        }
      }
    }
  `)
  const menuItems = data.mainMenu.menuItems?.nodes
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <div>
      <nav className="flex flex-row   gap-8 justify-between  py-4 md:py-8 mb-6 ">
        <div className="flex flex-shrink-0 mr-6 ">
          <Link to="/" title="Hem">
            <h1 className="font-bold">Studio 73A</h1>
          </Link>
        </div>
        <div>
          <Link to="/contact" className="underline underline-offset-2">
            Contact
          </Link>
        </div>
      </nav>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

function MobileMenu({ menuItems }) {
  useLockBodyScroll()

  return (
    <div className="fixed top-0 left-0 overflow-x-hidden z-10 bg-white inset-0 h-screen ">
      <div className="relative h-full     text-black  ">
        <div className="pt-2 pb-6 px-5 h-full  flex flex-col items-center justify-center text-2xl md:text-3xl uppercase font-bold">
          <div>
            <div className=" space-y-8 max-w-xl w-full mx-auto flex flex-col">
              {menuItems.map((item, i) => {
                return (
                  <Link key={i} to={item.url}>
                    {item.label}
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function useLockBodyScroll() {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow
    // Prevent scrolling on mount
    document.body.style.overflow = "hidden"
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle)
  }, []) // Empty array ensures effect is only run on mount and unmount
}
